.Container_home {
    width: 100%;
    height: 100vh;
    background-image: url('../media/pexels-splash-of-rain-7320.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img_intro {
    max-width: 500px;
    width: 100%;
}

@media (max-width: 768px) {
    .img_intro {
        max-width: 300px;
    }

    .Container_home {
        background-size: cover;
        background-position: 58% 50%;
        background-repeat: no-repeat;
    }
}



