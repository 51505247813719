.Row_moduleHeading, .Row_module{
    padding: 30px 30px 0px 30px;
    margin: 20px;
}

.Row_module{
    padding: 0px 30px 30px 30px;
    margin: 20px;
    justify-content: center;
}

.Row_moduleHeading{
    align-items: center;
}


.Row_moduleHeading h1{
    font-weight: 200;
}

.Card_Module {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin: 0px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    /*transition: transform 0.5s ease, border-color 0.5s ease;*/
    border-radius: 10px !important;
    background-color: #283048 !important;
}

.Card_Module h1 {
    font-size: 24px;
    color: white;
    padding: 10px 0px;
    text-align: left;
    /*text-shadow: 0 4px 8px rgba(247, 193, 5, 1) !important;*/
    text-shadow: 0 4px 8px rgba(255, 255, 255, 0.2) !important;
}

.Card_Module .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
}

.Card_Header {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.Card_Module .card-body .card-title {
    font-size: 18px;
    font-weight: 600;
}

.Card_Module .card-body .card-text {
    font-size: 13px;
}

.Card_Module .card-img-top {
    width: 130px;
    height: auto;
    display: block;
    padding: 0px 10px;
}

.Card_Module .card-body button {
    margin: 0px 0px 0px 0px;
    background-color: transparent;
    border: transparent;
    text-align: left;
    font-size: 14px;
    padding: 0px;
    color: #f7c105;
}

.Card_Module .card-body button:hover {
    margin: 0px 0px 0px 0px;
    background-color: transparent;
    border: transparent;
}

.container-column-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media (max-width: 350px) {
    .Row_moduleHeading h1 {
        font-size: 22px;
    }
}