.Container_about{
    padding: 20px;
    margin: 20px;
    height: 100vh;
    align-content: center;
    align-items: center;
    background-color: #283048;
    color: white;
}

.Col_about p{
    font-family: "Rubik", "DM Sans", sans-serif;
    font-size: 18px;
    padding-top: 20px;
}

.Col_aboutLogo, .Col_about{
    justify-content: center;
    align-content: center;
    margin: 40px;
}

.Col_aboutLogo{
    text-align: center;
}

.img_about {
    max-width: 500px;
}

.container-popup-secondary {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

@media (max-width: 991.90px){
    .img_about {
        max-width: 400px;
        margin-left: -40px;
    }

    .Col_about h1 {
        font-size: 30px;
    }

    .Col_about p {
        font-size: 18px;
    }
}

@media (max-width: 767.90px) {
    .img_about {
        max-width: 300px;
        margin-left: -60px;
    }

    .Col_about h1 {
        font-size: 26px;
    }

    .Col_about p {
        font-size: 16px;
    }
}

@media (max-width: 400px) {
    .img_about {
        max-width: 250px;
        margin-left: -60px;
    }

    .Col_about h1 {
        font-size: 24px;
    }

    .Col_about p {
        font-size: 14px;
    }
}


