@keyframes fade-in {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.Popup {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.PopupInner {
    position: relative;
    padding: 50px;
    width: 100%;
    max-width: 640px;
    background: rgba(40, 48, 72, 0.8);
    backdrop-filter: blur(30px);
    border-radius: 20px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    text-align: left;
    margin: 20px;
    animation: fade-in 0.5s;
}

.PopupInner .card-title {
    padding: 20px;
    text-align: center;
}

@media (max-width: 576px) {
    .Popup {
        margin-top: 30px;
    }
    .PopupInner {
        flex-direction: column;
    }
}
