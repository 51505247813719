.Navbar_main{
    background-color: #283048;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1030;
    color: white;
}

.navbar-nav {
    margin-left: auto;
}

.navbar-nav .nav-item {
    margin: 10px 10px;
}

.Navbar_Toggle {
    border: 1px solid #283048;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
}

.Navbar_Toggle .navbar-toggler-icon {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="rgba(255, 199, 0, 1)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/></svg>');
    width: 30px;
    height: 30px;
}


.Navbar_Toggle:focus,
.Navbar_Toggle:active {
    outline: none;
    box-shadow: none;
    border-color: rgba(255, 199, 0, 1);
}


.Navbar_Toggle:hover .navbar-toggler-icon {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="rgba(255, 199, 0, 0.7)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/></svg>');
}

