.Container_contact{
    height: 50vh;
    width: 100%;
    background-color: #283048;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
}

.Row_contact{
    padding: 50px 30px 10px 30px;
    margin: 10px;
}

.Col_contact{
    padding: 30px 10px 0px 10px;
}

.Col_contact p{
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.Col_contact a{
    text-decoration: none;
    color: inherit;
}

/*.Row_credits a{*/
/*    text-decoration: none;*/
/*    color: inherit;*/
/*    font-size: 10px;*/
/*    padding-left: 43px;*/
/*    !*margin-bottom: 15px;*!*/
/*}*/

.Row_credits p{
    font-size: 10px;
    margin-top: 40px;
}

@media (max-width: 400px) {
    .Row_contact h3 {
        font-size: 18px;
    }

    .Col_contact p {
        font-size: 12px;
    }

    .Col_contact a {
        font-size: 12px;
    }

    /*.Row_credits a {*/
    /*    font-size: 8px;*/
    /*}*/
    .Row_credits p{
        font-size: 8px;
    }
}