.Col_introduction, .Col_intro_points{
    padding: 70px 40px 0px 40px;
    margin: 20px;
}

.Container_introduction{
    height: 50vh;
}

.Col_intro_points li{
    font-size: 18px;
}


@media (max-width: 1126px) {
    .Col_introduction {
        padding-left: 10px;
        padding-right: 10px;
        margin-right: -20px;
    }

    .Col_intro_points{
        padding-left: 0px;
        padding-right: 10px;
    }

    .Col_introduction h1 {
        font-size: 30px;
    }

    .Col_introduction h4 {
        font-size: 18px;
    }

    .Col_intro_points li {
        font-size: 15.7px;
    }
}


@media (max-width: 767.90px) {
    .Container_introduction{
        height: 70vh;
    }

    .Col_introduction {
        padding-top: 30px;
        padding-right: 50px;
    }

    .Col_intro_points{
        padding-top: 0px;
    }

    .Col_introduction h1 {
        font-size: 26px;
    }

    .Col_introduction h4 {
        font-size: 15px;
    }

    .Col_intro_points li {
        font-size: 16px;
    }
}

@media (max-width: 400px) {
    .Container_introduction{
        height: 85vh;
    }

    .Col_introduction {
        padding-top: 30px;
        padding-right: 50px;
    }

    .Col_intro_points{
        padding-top: 0px;
    }

    .Col_introduction h1 {
        font-size: 24px;
    }

    .Col_introduction h4 {
        font-size: 13px;
    }

    .Col_intro_points li {
        font-size: 14px;
    }
}
